<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="purchasingkblist" v-if="yemian">
        <div class="kanbanlist_box">
            <i class="el-icon-error" @click="guanbi"></i>
            <div class="kanban_listbox_tit">
                <div class="kanban_listbox_tit_left">
                    <span @click='xianshi_shezhi=true'>显示设置</span>
                    <span @click="dianji_daochu">导出</span>
                    <span @click="biaoyi_num=index" :style="biaoyi_num==index?'color:#fff;background: #9a86db;border:0.01rem solid #9a86db;':''" v-for="(i,index) in huizong_or_mingxi" :key='index'>{{i}}</span>
                    <p style="margin-right:0.2rem;">单位：元</p>
                </div>
                <p class="kanban_listbox_tit_right">
                    <i class="el-icon-search"></i>
                    <input type="text" v-model="sousuo_text" @keyup.enter="sousuo_1" placeholder="请输入">
                    <i @click="sousuo_text='',sousuo_1()" class="el-icon-circle-close"></i>
                </p>
            </div>

            <div class="kanban_listbox_table">
                <p style="text-align:center;font-size:0.16rem;">{{biao_name}}</p>
                <div style="padding:0 0.3rem;">
                    <p>11111</p>
                    <p v-if="biao_name=='采购单'">
                        采购数量合计：{{caigou_shuliang_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        采购金额合计：{{caigou_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <p v-if="biao_name=='入库'">
                        入库数量合计：{{ruku_shuliang_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        入库金额合计：{{ruku_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <p v-if="biao_name=='采购发票'">
                        累计付款金额合计：{{leiji_fukuan_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计入库金额合计：{{leiji_ruku_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        采购发票累计未结账金额合计：{{leiji_wei_jiezhang_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        采购单累计未付款金额合计：{{leiji_wei_fukuan_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <p v-if="biao_name=='付款'">
                        累计付款金额合计：{{leiji_fukuan_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计入库金额合计：{{leiji_ruku_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        采购发票累计未结账金额合计：{{leiji_wei_jiezhang_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        采购单累计未付款金额合计：{{leiji_wei_fukuan_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                </div>
                <!--  :span-method="biaoyi_num==1?hebing:wuyong()" -->
                <el-table
                    :data="dangqian_xiangqing"
                    :cell-style="liebiao"
                    max-height="640"
                    :header-cell-style="biaotou"
                    tooltip-effect="dark"
                    style="width: 100%">
                    <el-table-column v-for="(i,index) in xianshi_tit" :key="index"
                        :prop="i.con"
                        :label="i.name"
                        >
                        <template slot-scope="props">{{props.row[i.con]}}
                            <span v-if="props.row[i.con]==undefined||props.row[i.con].length==0">/</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="beijing" v-if="xianshi_shezhi" @click="xianshi_shezhi=false">
            <div class="beijing_box" @click.stop>
                <p class="beijing_box_tit">显示设置</p>
                <div class="beijing_box_con">
                    <div>
                        <p class="beijing_box_con_tit">选择：</p>
                        <p class="beijing_box_con_quanbu" @click="dianji_xuanze_quanbu"><img :src="xianshi_quanbu?require('../../assets/okblue.png'):require('../../assets/noccc.png')">全部</p>
                        <ul>
                            <li v-for="(i,index) in xianshi_list" :key="index" @click="xuanze_danxuan(i)">
                                <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')">{{i.name}}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p class="beijing_box_con_tit">显示项：</p>
                        <ul>
                            <li v-for='(i,index) in xianshi_xiang_list' :key="index">
                                {{i.name}}<img class="shanchu_xianshi" @click="dianji_shanchu_xianshi(i,index)" src="../../assets/guanbi_zhu.png">
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="beijing_box_foot">
                    <span @click="dianji_lie_queding">确定</span>
                    <span @click="xianshi_shezhi=false">取消</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { query_form_list_gather, query_form_list } from '../../api/api.js'
export default {
  name: 'purchasingkblist',
  data () {
    return {
      yemian: false,
      biao1_tit: [],
      dangqian_xiangqing: [],
      dangqian_xiangqing2: [],
      xianshi_shezhi: false,
      xianshi_quanbu: false,
      zhanshi_list: [],
      xianshi_xiang_list: [],
      xianshi_shezhi2: false,
      xianshi_quanbu2: false,
      zhanshi_list2: [],
      xianshi_xiang_list2: [],
      dianji_chuxian_youce: false,
      biao_name: '',
      youce_xiangqing: '',
      sousuo_text: '',
      sousuo_text2: '',
      huizong_or_mingxi: ['汇总', '明细'],
      biaoyi_num: '0',
      biaoer_num: '0',
      xianshi_list: [],
      xianshi2_list: [],
      spanArr3: [],
      pos3: 0,
      indexArr3: [],
      dangqian_neirong: [], // 派工单用到了
      no_mingxi: false,

      xianshi_tit: [],
      xianshi2_tit: [],
      look_type: '',
      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/',
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',
      //  url:'http://192.168.3.5:8080/hwb_client/V1.0.0/',

      caigou_jine_heji: '0.00', // 采购金额合计
      caigou_shuliang_heji: '0.00', // 采购数量合计
      ruku_jine_heji: '0.00', // 入库金额合计
      ruku_shuliang_heji: '0.00', // 入库数量合计
      leiji_fukuan_jine_heji: '0.00', // 累计付款金额合计
      leiji_ruku_jine_heji: '0.00', // 累计入库金额合计
      leiji_wei_jiezhang_heji: '0.00', // 采购发票累计未结账金额合计
      leiji_wei_fukuan_heji: '0.00', // 采购发票累计未付款金额合计

      guolv_text: ''
    }
  },
  watch: {
    xianshi_xiang_list: {
      handler (newValue, oldValue) {
        if (this.xianshi_xiang_list.length == this.xianshi_list.length && this.xianshi_xiang_list.length != 0) {
          this.xianshi_quanbu = true
        } else {
          this.xianshi_quanbu = false
        }
      },
      deep: true
    },
    xianshi_xiang_list2: {
      handler (newValue, oldValue) {
        if (this.xianshi_xiang_list2.length == this.xianshi2_list.length && this.xianshi_xiang_list2.length != 0) {
          this.xianshi_quanbu2 = true
        } else {
          this.xianshi_quanbu2 = false
        }
      },
      deep: true
    },
    xianshi_shezhi () {
      if (!this.xianshi_quanbu) {
        this.xianshi_list.map(i => {
          this.$set(i, 'zhi', false)
        })
        this.xianshi_xiang_list = []
      }
    },
    xianshi_shezhi2 () {
      if (!this.xianshi_quanbu2) {
        this.xianshi2_list.map(i => {
          this.$set(i, 'zhi', false)
        })
        this.xianshi_xiang_list2 = []
      }
    },
    biaoyi_num () { // 表一里的切换汇总还是明细按钮
      this.jichu_zhi(this.biao_name)
    },
    biaoer_num () { // 表二里的切换汇总还是明细按钮
      // this.qiehuan_hz_mx2()
      this.jichu_zhi(this.biao_name)
    }
    // dangqian_xiangqing:{
    //     handler(newValue,oldValue){
    //         this.zidong_jisuan()
    //     },
    //     deep:true
    // }
  },
  mounted () {
  },
  created () {
  },
  methods: {
    jichu (i) {
      console.log(i)

      this.biaoyi_num = 0
      this.biaoer_num = 0
      this.sousuo_text = ''
      this.sousuo_text2 = ''
      // tongxun.$on('liebiao_name',(i)=>{
      this.biao_name = i.name
      this.look_type = i.look_type
      this.jichu_zhi(i.name)
      this.yemian = true
      // })
    },
    jichu_zhi (table) {
      this.biao1_tit = []
      this.zhanshi_list = []
      this.biao1_tit2 = []
      this.zhanshi_list2 = []
      this.dangqian_xiangqing = []
      this.dangqian_xiangqing2 = []
      if (table == '采购单') {
        this.huizong_or_mingxi = ['汇总', '明细']
        this.biao1_tit = [
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '采购日期',
            con: 'date_pur'
          },
          {
            name: '采购数量合计',
            con: 'qty_pur_sum'
          },
          {
            name: '采购金额合计',
            con: 'amt_pur_sum'
          }
        ]
        this.biao2_tit = [
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '采购日期',
            con: 'date_pur'
          },
          {
            name: '采购数量合计',
            con: 'qty_pur_sum'
          },
          {
            name: '采购金额合计',
            con: 'amt_pur_sum'
          },
          {
            name: '产品',
            con: 'prod_name'
          },
          {
            name: '产品编号',
            con: 'prod_code'
          },
          {
            name: '规格',
            con: 'spec'
          },
          {
            name: '型号',
            con: 'type'
          },
          {
            name: '单位',
            con: 'unit'
          },
          {
            name: '库存数量',
            con: 'qty_stock'
          },
          {
            name: '供应商',
            con: 'supply_name'
          },
          {
            name: '产品报价',
            con: 'price_quotation'
          },
          {
            name: '采购数量',
            con: 'qty_pur'
          },
          {
            name: '采购金额',
            con: 'amt_pur'
          },
          {
            name: '预计库存',
            con: 'qty_stock_est'
          }
        ]
        this.xianshi_tit = this.biao1_tit
        const zhanshi = JSON.parse(JSON.stringify(this.biao1_tit))
        zhanshi.forEach(item => {
          this.$set(item, 'zhi', false)
        })
        this.zhanshi1_list = zhanshi
        const zhanshi2 = JSON.parse(JSON.stringify(this.biao2_tit))
        zhanshi2.forEach(item => {
          this.$set(item, 'zhi', false)
        })
        this.zhanshi2_list = zhanshi2
        this.xianshi_list = this.zhanshi1_list
        if (this.biaoyi_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'purchase',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              // data_gather
              const data = JSON.parse(res.data.body.data_gather)
              this.caigou_shuliang_heji = data.qty_pur_sum
              this.caigou_jine_heji = data.amt_pur_sum
              console.log(date)

              if (this.guolv_text.length != 0) {
                const list = []
                date.forEach(item => {
                  if (item.supply_id == this.guolv_text) {
                    list.push(item)
                  }
                })
                this.dangqian_xiangqing = list
                this.dangqian_neirong = list
              } else {
                this.dangqian_xiangqing = date
                this.dangqian_neirong = date
              }
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoyi_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'purchase',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              const data = JSON.parse(res.data.body.data_gather)
              this.caigou_shuliang_heji = data.qty_pur_sum
              this.caigou_jine_heji = data.amt_pur_sum

              if (this.guolv_text.length != 0) {
                const list = []
                date.forEach(item => {
                  if (item.supply_id == this.guolv_text) {
                    list.push(item)
                  }
                })
                this.dangqian_xiangqing = list
                this.dangqian_neirong = list
              } else {
                this.dangqian_xiangqing = date
                this.dangqian_neirong = date
              }
              this.qiehuan_hz_mx()
            } else if (res.data.code == 500) {}
          })
        }
      } else if (table == '入库') {
        this.huizong_or_mingxi = ['汇总', '明细']
        this.biao1_tit = [
          {
            name: '入库员',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '入库日期',
            con: 'date_in_store'
          },
          {
            name: '供应商',
            con: 'supply_name'
          },
          {
            name: '入库数量合计',
            con: 'qty_in_store_sum'
          },
          {
            name: '入库金额合计',
            con: 'amt_in_store_sum'
          }
        ]
        this.biao2_tit = [
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '入库日期',
            con: 'date_in_store'
          },
          {
            name: '供应商',
            con: 'supply_name'
          },
          {
            name: '入库数量合计',
            con: 'qty_in_store_sum'
          },
          {
            name: '入库金额合计',
            con: 'amt_in_store_sum'
          },
          {
            name: '产品',
            con: 'prod_name'
          },
          {
            name: '产品编号',
            con: 'prod_code'
          },
          {
            name: '规格',
            con: 'spec'
          },
          {
            name: '型号',
            con: 'type'
          },
          {
            name: '单位',
            con: 'unit'
          },
          {
            name: '仓库',
            con: 'site_name'
          },
          {
            name: '货位号',
            con: 'site_no'
          },
          {
            name: '供应商',
            con: 'supply_name'
          },
          {
            name: '产品报价',
            con: 'price_quotation'
          },
          {
            name: '入库数量',
            con: 'qty_in_store_prod'
          },
          {
            name: '入库金额',
            con: 'amt_in_store_prod'
          }
        ]
        this.xianshi_tit = this.biao1_tit
        const zhanshi = JSON.parse(JSON.stringify(this.biao1_tit))
        zhanshi.forEach(item => {
          this.$set(item, 'zhi', false)
        })
        this.zhanshi1_list = zhanshi
        const zhanshi2 = JSON.parse(JSON.stringify(this.biao2_tit))
        zhanshi2.forEach(item => {
          this.$set(item, 'zhi', false)
        })
        this.zhanshi2_list = zhanshi2
        this.xianshi_list = this.zhanshi1_list
        if (this.biaoyi_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'in_store',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              const data = JSON.parse(res.data.body.data_gather)
              this.ruku_shuliang_heji = data.qty_ins_sum
              this.ruku_jine_heji = data.amt_ins_sum

              if (this.guolv_text.length != 0) {
                const list = []
                date.forEach(item => {
                  if (item.supply_id == this.guolv_text) {
                    list.push(item)
                  }
                })
                this.dangqian_xiangqing = list
                this.dangqian_neirong = list
              } else {
                this.dangqian_xiangqing = date
                this.dangqian_neirong = date
              }
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoyi_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'in_store',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              const data = JSON.parse(res.data.body.data_gather)
              this.ruku_shuliang_heji = data.qty_ins_sum
              this.ruku_jine_heji = data.amt_ins_sum

              if (this.guolv_text.length != 0) {
                const list = []
                date.forEach(item => {
                  if (item.supply_id == this.guolv_text) {
                    list.push(item)
                  }
                })
                this.dangqian_xiangqing = list
                this.dangqian_neirong = list
              } else {
                this.dangqian_xiangqing = date
                this.dangqian_neirong = date
              }
              this.qiehuan_hz_mx()
            } else if (res.data.code == 500) {}
          })
        }
      } else if (table == '采购发票') {
        this.huizong_or_mingxi = ['汇总', '明细']
        this.biao1_tit = [
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '发票日期',
            con: 'date_pay'
          },
          {
            name: '供应商',
            con: 'supply_name'
          },
          {
            name: '发票类型',
            con: 'fapiao_leixing'
          },
          {
            name: '发票代码',
            con: 'inv_code'
          },
          {
            name: '发票号码',
            con: 'inv_no'
          },
          {
            name: '税额合计',
            con: 'amt_tax_sum'
          },
          {
            name: '入库金额',
            con: 'amt_in_store'
          },
          {
            name: '发票金额合计',
            con: 'amt_inv_sum'
          },
          {
            name: '预计付款金额合计',
            con: 'amt_pay_est_sum'
          }
        ]
        this.biao2_tit = [
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '发票日期',
            con: 'date_pay'
          },
          {
            name: '供应商',
            con: 'supply_name'
          },
          {
            name: '发票类型',
            con: 'fapiao_leixing'
          },
          {
            name: '发票代码',
            con: 'inv_code'
          },
          {
            name: '发票号码',
            con: 'inv_no'
          },
          {
            name: '税额合计',
            con: 'amt_tax_sum'
          },
          {
            name: '入库金额',
            con: 'amt_in_store'
          },
          {
            name: '发票金额合计',
            con: 'amt_inv_sum'
          },
          {
            name: '预计付款金额合计',
            con: 'amt_pay_est_sum'
          },
          {
            name: '产品',
            con: 'prod_name'
          },
          {
            name: '产品编号',
            con: 'prod_code'
          },
          {
            name: '规格',
            con: 'spec'
          },
          {
            name: '型号',
            con: 'type'
          },
          {
            name: '单位',
            con: 'unit'
          },
          {
            name: '供应商',
            con: 'supply_name'
          },
          {
            name: '产品报价',
            con: 'price_quotation'
          },
          {
            name: '采购数量',
            con: 'qty_pur'
          },
          {
            name: '发票金额',
            con: 'amt_inv'
          },
          {
            name: '税率',
            con: 'rate_tax'
          },
          {
            name: '税额',
            con: 'amt_tax'
          },
          {
            name: '不含税金额',
            con: 'amt_no_tax'
          }
        ]
        this.xianshi_tit = this.biao1_tit
        const zhanshi = JSON.parse(JSON.stringify(this.biao1_tit))
        zhanshi.forEach(item => {
          this.$set(item, 'zhi', false)
        })
        this.zhanshi1_list = zhanshi
        const zhanshi2 = JSON.parse(JSON.stringify(this.biao2_tit))
        zhanshi2.forEach(item => {
          this.$set(item, 'zhi', false)
        })
        this.zhanshi2_list = zhanshi2
        this.xianshi_list = this.zhanshi1_list
        if (this.biaoyi_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'purchase_invoice',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              const data = JSON.parse(res.data.body.data_gather)
              this.leiji_fukuan_jine_heji = data.amt_pay_sum
              this.leiji_ruku_jine_heji = data.amt_ins_sum
              this.leiji_wei_jiezhang_heji = data.amt_un_pay_ofinv_sum
              this.leiji_wei_fukuan_heji = data.amt_un_pay_sum
              date.forEach(item => {
                this.$set(item, 'fapiao_leixing', this.fapiao_leixing_hanzi(item.inv_type))
              })

              if (this.guolv_text.length != 0) {
                const list = []
                date.forEach(item => {
                  if (item.supply_id == this.guolv_text) {
                    list.push(item)
                  }
                })
                this.dangqian_xiangqing = list
                this.dangqian_neirong = list
              } else {
                this.dangqian_xiangqing = date
                this.dangqian_neirong = date
              }
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoyi_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'purchase_invoice',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              const data = JSON.parse(res.data.body.data_gather)
              this.leiji_fukuan_jine_heji = data.amt_pay_sum
              this.leiji_ruku_jine_heji = data.amt_ins_sum
              this.leiji_wei_jiezhang_heji = data.amt_un_pay_ofinv_sum
              this.leiji_wei_fukuan_heji = data.amt_un_pay_sum
              date.forEach(item => {
                this.$set(item, 'fapiao_leixing', this.fapiao_leixing_hanzi(item.inv_type))
              })

              if (this.guolv_text.length != 0) {
                const list = []
                date.forEach(item => {
                  if (item.supply_id == this.guolv_text) {
                    list.push(item)
                  }
                })
                this.dangqian_xiangqing = list
                this.dangqian_neirong = list
              } else {
                this.dangqian_xiangqing = date
                this.dangqian_neirong = date
              }
              this.qiehuan_hz_mx()
            } else if (res.data.code == 500) {}
          })
        }
      } else if (table == '付款') {
        this.biao1_tit = [
          {
            name: '付款日期',
            con: 'operate_time'
          },
          {
            name: '供应商',
            con: 'supply_name'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '采购发票累计未结账金额',
            con: 'amt_pur_inv_un_pay'
          },
          {
            name: '采购单累计未付款金额',
            con: 'amt_pur_un_pay'
          },
          {
            name: '累计入库金额',
            con: 'amt_in_store'
          },
          {
            name: '付款金额',
            con: 'amt_pay'
          },
          {
            name: '累计付款金额',
            con: 'amt_pay_sum'
          },
          {
            name: '开户行名称',
            con: 'bank_name'
          },
          {
            name: '银行账号',
            con: 'bank_acct'
          }
        ]
        this.biao2_tit = [
          {
            name: '付款日期',
            con: 'operate_time'
          },
          {
            name: '供应商',
            con: 'supply_name'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '采购发票累计未结账金额',
            con: 'amt_pur_inv_un_pay'
          },
          {
            name: '采购单累计未付款金额',
            con: 'amt_pur_un_pay'
          },
          {
            name: '累计入库金额',
            con: 'amt_in_store'
          },
          {
            name: '付款金额',
            con: 'amt_pay'
          },
          {
            name: '累计付款金额',
            con: 'amt_pay_sum'
          },
          {
            name: '开户行名称',
            con: 'bank_name'
          },
          {
            name: '银行账号',
            con: 'bank_acct'
          }
        ]
        this.huizong_or_mingxi = ['汇总']
        this.xianshi_tit = this.biao1_tit
        const zhanshi = JSON.parse(JSON.stringify(this.biao1_tit))
        zhanshi.forEach(item => {
          this.$set(item, 'zhi', false)
        })
        this.zhanshi1_list = zhanshi
        const zhanshi2 = JSON.parse(JSON.stringify(this.biao2_tit))
        zhanshi2.forEach(item => {
          this.$set(item, 'zhi', false)
        })
        this.zhanshi2_list = zhanshi2
        this.xianshi_list = this.zhanshi1_list
        // if(this.biaoyi_num==0){
        //     query_form_list_gather({data:{
        //         ent_id:this.$ent_id(),
        //         form_type:'payment',
        //         flow_status:'4',
        //         user_id:this.$jichuxinxi().user_id,
        //         look_type:this.look_type
        //     }}).then(res=>{
        //         if(res.data.code==200){
        //             let date=JSON.parse(res.data.body.data)
        //             console.log(date)
        //             let data=JSON.parse(res.data.body.data_gather)
        //             this.leiji_fukuan_jine_heji=data.amt_pay_sum
        //             this.leiji_ruku_jine_heji=data.amt_ins_sum
        //             this.leiji_wei_jiezhang_heji=data.amt_un_pay_ofinv_sum
        //             this.leiji_wei_fukuan_heji=data.amt_un_pay_sum
        //             this.dangqian_xiangqing=date
        //             this.dangqian_neirong=date
        //         }else if(res.data.code==500){}
        //     })
        // }else if(this.biaoyi_num==1){
        query_form_list({
          data: {
            ent_id: this.$ent_id(),
            form_type: 'payment',
            flow_status: '4',
            user_id: this.$jichuxinxi().user_id,
            look_type: this.look_type
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            const data = JSON.parse(res.data.body.data_gather)
            this.leiji_fukuan_jine_heji = data.amt_pay_sum
            this.leiji_ruku_jine_heji = data.amt_ins_sum
            this.leiji_wei_jiezhang_heji = data.amt_un_pay_ofinv_sum
            this.leiji_wei_fukuan_heji = data.amt_un_pay_sum

            if (this.guolv_text.length != 0) {
              const list = []
              date.forEach(item => {
                if (item.supply_id == this.guolv_text) {
                  list.push(item)
                }
              })
              this.dangqian_xiangqing = list
              this.dangqian_neirong = list
            } else {
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
            }
            this.qiehuan_hz_mx()
          } else if (res.data.code == 500) {}
        })
        // }
      }
    },
    guanbi () {
      this.yemian = false
      this.biaoyi_num = '0'
      this.biaoer_num = '0'
      this.spanArr3 = []
      this.pos3 = 0
    },
    fapiao_leixing_hanzi (num) {
      if (num == 1) {
        return '增值税专用发票(抵扣)'
      } else if (num == 2) {
        return '增值税专用发票(不抵扣)'
      } else if (num == 3) {
        return '增值税普通发票'
      } else if (num == 4) {
        return '不开发票'
      }
    },
    jieduan_hanzi (i) {
      if (i == 1) {
        return '初步接触'
      } else if (i == 2) {
        return '需求确认'
      } else if (i == 3) {
        return '报价及方案展示'
      } else if (i == 4) {
        return '商务谈判'
      } else if (i == 5) {
        return '赢单'
      } else if (i == 6) {
        return '输单'
      }
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    // 点击选择全部
    dianji_xuanze_quanbu () {
      this.xianshi_quanbu = !this.xianshi_quanbu
      if (this.xianshi_quanbu) {
        this.xianshi_list.map((i, index) => { i.zhi = true })
        this.xianshi_xiang_list = []
        for (let i = 0; i < this.xianshi_list.length; i++) {
          this.xianshi_xiang_list.push(this.xianshi_list[i])
        }
      } else {
        this.xianshi_list.map((i, index) => { i.zhi = false })
        this.xianshi_xiang_list = []
      }
    },
    xuanze_danxuan (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.xianshi_xiang_list.push(i)
      } else {
        for (let a = 0; a < this.xianshi_xiang_list.length; a++) {
          if (this.xianshi_xiang_list[a].con == i.con) {
            this.xianshi_xiang_list.splice(a, 1)
          }
        }
      }
    },
    dianji_shanchu_xianshi (i, index) {
      this.xianshi_list.map((item, idx) => {
        if (item.con == i.con) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list.splice(index, 1)
    },
    dianji_lie_queding () {
      this.xianshi_tit = this.xianshi_xiang_list
      this.xianshi_xiang_list = []
      this.xianshi_shezhi = false
    },
    // 点击选择全部
    dianji_xuanze_quanbu2 () {
      this.xianshi_quanbu2 = !this.xianshi_quanbu2
      if (this.xianshi_quanbu2) {
        this.xianshi2_list.map((i, index) => { i.zhi = true })
        this.xianshi_xiang_list2 = []
        for (let i = 0; i < this.xianshi2_list.length; i++) {
          this.xianshi_xiang_list2.push(this.xianshi2_list[i])
        }
      } else {
        this.xianshi2_list.map((i, index) => { i.zhi = false })
        this.xianshi_xiang_list2 = []
      }
    },
    xuanze_danxuan2 (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.xianshi_xiang_list2.push(i)
      } else {
        for (let a = 0; a < this.xianshi_xiang_list2.length; a++) {
          if (this.xianshi_xiang_list2[a].con == i.con) {
            this.xianshi_xiang_list2.splice(a, 1)
          }
        }
      }
    },
    dianji_shanchu_xianshi2 (i, index) {
      this.xianshi2_list.map((item, idx) => {
        if (item.con == i.con) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list2.splice(index, 1)
    },
    dianji_lie_queding2 () {
      this.xianshi2_tit = this.xianshi_xiang_list2
      this.xianshi_xiang_list2 = []
      this.xianshi_shezhi2 = false
    },
    type_zhuanhua (i) {
      if (i == '采购单') {
        return 'purchase'
      } else if (i == '入库') {
        return 'in_store'
      } else if (i == '采购发票') {
        return 'purchase_invoice'
      } else if (i == '付款') {
        return 'payment'
      }
    },
    sousuo_1 () {
      if (this.biaoyi_num == 0) {
        query_form_list_gather({
          data: {
            ent_id: this.$ent_id(),
            form_type: this.type_zhuanhua(this.biao_name),
            search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
            flow_status: '4',
            user_id: this.$jichuxinxi().user_id,
            look_type: this.look_type
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            if (this.biao_name == '漏斗阶段') {
              date.forEach(item => {
                this.$set(item, 'jieduan', this.jieduan_hanzi(item.stage_funn))
                this.$set(item, 'loudou_zhesuan_jine', (Number(item.rate_funn_disc) * Number(item.amt_funn)).toFixed(2))
                this.$set(item, 'bili', Number(item.rate_funn_disc) * 100 + '%')
              })
            } else if (this.biao_name == '合同' || this.biao_name == '发票' || this.biao_name == '回款') {
              date.forEach(item => {
                Object.assign(item, item.cl)
              })
            } else if (this.biao_name == '访客记录') {
              date.forEach(it => {
                this.$set(it, 'loudou_xianjie_duan', this.jieduan_hanzi(it.current_stage))
                this.$set(it, 'yuji_xiayi_jieduan', this.jieduan_hanzi(it.next_stage))
              })
            } else if (this.biao_name == '采购发票') {
              date.forEach(it => {
                this.$set(it, 'fapiao_leixing', this.fapiao_leixing_hanzi(it.inv_type))
              })
            }

            if (this.guolv_text.length != 0) {
              const list = []
              date.forEach(item => {
                if (item.supply_id == this.guolv_text) {
                  list.push(item)
                }
              })
              this.dangqian_xiangqing = list
              this.dangqian_neirong = list
            } else {
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
            }
          } else if (res.data.code == 500) {}
        })
      } else if (this.biaoyi_num == 1) {
        query_form_list({
          data: {
            ent_id: this.$ent_id(),
            form_type: this.type_zhuanhua(this.biao_name),
            search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
            flow_status: '4',
            user_id: this.$jichuxinxi().user_id,
            look_type: this.look_type
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            if (this.biao_name == '漏斗阶段') {
              date.forEach(item => {
                this.$set(item, 'jieduan', this.jieduan_hanzi(item.stage_funn))
                this.$set(item, 'loudou_zhesuan_jine', (Number(item.rate_funn_disc) * Number(item.amt_funn)).toFixed(2))
                this.$set(item, 'bili', Number(item.rate_funn_disc) * 100 + '%')
              })
            } else if (this.biao_name == '合同' || this.biao_name == '发票' || this.biao_name == '回款') {
              date.forEach(item => {
                Object.assign(item, item.cl)
              })
            } else if (this.biao_name == '访客记录') {
              date.forEach(it => {
                this.$set(it, 'loudou_xianjie_duan', this.jieduan_hanzi(it.current_stage))
                this.$set(it, 'yuji_xiayi_jieduan', this.jieduan_hanzi(it.next_stage))
              })
            } else if (this.biao_name == '采购发票') {
              date.forEach(it => {
                this.$set(it, 'fapiao_leixing', this.fapiao_leixing_hanzi(it.inv_type))
              })
            }

            if (this.guolv_text.length != 0) {
              const list = []
              date.forEach(item => {
                if (item.supply_id == this.guolv_text) {
                  list.push(item)
                }
              })
              this.dangqian_xiangqing = list
              this.dangqian_neirong = list
            } else {
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
            }
            this.qiehuan_hz_mx()
          } else if (res.data.code == 500) {}
        })
      }
    },
    // 切换汇总明细按钮
    qiehuan_hz_mx () {
      // this.biaoyi_num==0
      if (this.biaoyi_num == 0) {
        this.xianshi_tit = this.biao1_tit
        this.xianshi_list = this.zhanshi1_list
        this.spanArr3 = []
        this.pos3 = 0
        if (this.biao_name == '工单' || this.biao_name == '交付单' || this.biao_name == '漏斗阶段' || this.biao_name == '合同' || this.biao_name == '发票' || this.biao_name == '回款') {
          this.dangqian_xiangqing = this.dangqian_neirong // 当前内容不变
        }
      } else if (this.biaoyi_num == 1) {
        this.xianshi_tit = this.biao2_tit
        this.biaoyi_num = 1
        this.xianshi_list = this.zhanshi2_list
        console.log(this.dangqian_xiangqing)
        if (this.biao_name == '工单' || this.biao_name == '交付单' || this.biao_name == '漏斗阶段' || this.biao_name == '回款') {
          const date = this.dangqian_xiangqing
          const list = []
          date.forEach(item => {
            item.prod_detail.forEach(it => {
              const obj = {}
              Object.assign(obj, item, it)
              list.push(obj)
              // list.push
            })
          })
          console.log(list)
          this.dangqian_xiangqing = list
          // this.getSpanArr3(this.dangqian_xiangqing)
        }
        // else if(this.biao_name=='合同'||this.biao_name=='发票'){
        //     let date=this.dangqian_xiangqing
        //     // let list=[]
        //     // date.forEach(item=>{
        //     //     item.prod_list.forEach(it=>{
        //     //         let obj={}
        //     //         Object.assign(obj,item,it,item.amt_list[0])
        //     //         list.push(obj)
        //     //         // list.push
        //     //     })
        //     // })
        //     // console.log(list)
        //     this.dangqian_xiangqing=date
        //     // this.getSpanArr3(this.dangqian_xiangqing)
        // }
      }
    },
    qiehuan_hz_mx2 () {
      if (this.biaoer_num == 0) {
        this.xianshi2_tit = this.biao1_tit2
        this.xianshi2_list = this.zhanshi1_list2
      } else if (this.biaoer_num == 1) {
        this.xianshi2_tit = this.biao2_tit2
        this.xianshi2_list = this.zhanshi2_list2
      }
    },
    getSpanArr3 (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr3.push(1)
          this.pos3 = 0
        } else {
          // 判断当前元素与上一个元素是否相同（line为标记）
          if (data[i].cust_name === data[i - 1].cust_name) {
            this.spanArr3[this.pos3] += 1
            this.spanArr3.push(0)
          } else {
            this.spanArr3.push(1)
            this.pos3 = i
          }
        }
      }
    },
    hebing ({ row, column, rowIndex, columnIndex }) {
      if (this.indexArr3.includes(rowIndex)) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 3
          }
        } else if (columnIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      if (columnIndex === 0) {
        const _row = this.spanArr3[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    wuyong () {},
    no_mingxi_tishi () {
      this.$message({
        message: '不可进入明细',
        type: 'warning'
      })
    },
    dianji_daochu () {
      if (this.biaoyi_num == 0) {
        let date = ''
        if (this.biao_name == '采购单') {
          date = this.url + 'export_gather_pur?ent_id=' + this.$ent_id() + '&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type + '&supply_id=' + (this.guolv_text.length != 0 ? this.guolv_text : null)
        } else if (this.biao_name == '采购发票') {
          date = this.url + 'export_gather_pur_inv?ent_id=' + this.$ent_id() + '&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type + '&supply_id=' + (this.guolv_text.length != 0 ? this.guolv_text : null)
        } else if (this.biao_name == '入库') {
          date = this.url + 'export_gather_in_store?ent_id=' + this.$ent_id() + '&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type + '&supply_id=' + (this.guolv_text.length != 0 ? this.guolv_text : null)
        } else if (this.biao_name == '付款') {
          date = this.url + 'export_detail_payment?ent_id=' + this.$ent_id() + '&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type + '&supply_id=' + (this.guolv_text.length != 0 ? this.guolv_text : null)
        }

        this.$func.multiDownLoad([date])
      } else if (this.biaoyi_num == 1) {
        let date = ''
        if (this.biao_name == '采购单') {
          date = this.url + 'export_detail_pur?ent_id=' + this.$ent_id() + '&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type + '&supply_id=' + (this.guolv_text.length != 0 ? this.guolv_text : null)
        } else if (this.biao_name == '采购发票') {
          date = this.url + 'export_detail_pur_inv?ent_id=' + this.$ent_id() + '&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type + '&supply_id=' + (this.guolv_text.length != 0 ? this.guolv_text : null)
        } else if (this.biao_name == '入库') {
          date = this.url + 'export_detail_in_store?ent_id=' + this.$ent_id() + '&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type + '&supply_id=' + (this.guolv_text.length != 0 ? this.guolv_text : null)
        }
        this.$func.multiDownLoad([date])
      }
    },
    sousuo_zifu (text) {
      console.log(text)
      this.guolv_text = text
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../style/salesman.scss';
</style>
