<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="purchasingkb">
        <div class="purchasingkb_tit">
            <p v-if="kanban_ye">
                <span @click="dianji_jinru_caigou_guanli">
                    采购管理 >
                </span>
                采购看板
            </p>
            <p v-if="!kanban_ye">
                <span @click="kanban_ye=true">
                    采购看板 >
                </span>
                产品列表
            </p>
            <p>采购看板</p>
            <div v-if="kanban_ye">

            </div>
            <p v-if="!kanban_ye" @click="dianji_daochu">
                导出
            </p>
        </div>
        <!-- <div>
            供应商
            <el-tooltip class="item" effect="dark" :content="dangqian_gongying_shang.map(item=>item.supply_name).join(',')" placement="top-start">
                <p @click="dianji_xuanze_gongying_shang(null)">{{dangqian_gongying_shang.length!=0?(dangqian_gongying_shang.map(item=>item.supply_name).join(',')):'请选择'}}</p>
            </el-tooltip>
        </div>
        <ul v-if="kanban_ye" class="purchasingkb_btn">
            <li v-for="i in kanshei_list" :key="i.id" :class="kan_shei==i.id?'zise':''" @click="kan_shei=i.id">{{i.name}}</li>
        </ul> -->
        <div class="kanban_tit">
            <div v-if="kanban_ye">
                供应商
                <el-tooltip class="item" effect="dark" :content="dangqian_gongying_shang.map(item=>item.supply_name).join(',')" placement="top-start">
                    <p @click="dianji_xuanze_gongying_shang(null)">{{dangqian_gongying_shang.length!=0?(dangqian_gongying_shang.map(item=>item.supply_name).join(',')):'请选择'}}</p>
                </el-tooltip>
            </div>
            <ul v-if="kanban_ye" class="purchasingkb_btn">
                <li v-for="i in kanshei_list" :key="i.id" :class="kan_shei==i.id?'zise':''" @click="kan_shei=i.id">{{i.name}}</li>
            </ul>
        </div>
        <div class="kanban_box_table">
            <el-table
                v-if="kanban_ye"
                :data="kanban_neirong_list"
                :cell-style="liebiao"
                height="6.5rem"
                :header-cell-style="biaotou"
                style="width: 100%">
                <el-table-column
                    fixed
                    label=""
                    >
                    <template slot-scope="props">
                        <img class="zuanshi" src="../../assets/me/zuanshi.png" @click="dianji_zuanshi(props.row)" alt="">
                    </template>
                </el-table-column>
                <el-table-column
                    fixed
                    label="供应商"
                    >
                    <template slot-scope="props">
                        {{props.row.supply_name}}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed
                    label="负责人"
                    >
                    <template slot-scope="props">
                        {{props.row.staff_name}}
                    </template>
                </el-table-column>
                <el-table-column
                    width='180'
                    >
                    <template slot-scope="props">
                        <p>&nbsp;</p>
                        <div class="jiantou">
                            <p class="hengxian"></p>
                            <p class="el-icon-caret-right"></p>
                        </div>
                        <p>{{props.row.amt_pur}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="采购单"
                    width='130'
                    sortable
                    :sort-method="caigou_paixu"
                    :render-header="danju_liebiao"
                    >
                    <template slot-scope="props">
                        <!-- <p :class="props.row.status_pur==2?'green':(props.row.status_pur==1?'yellow':'red')"></p> -->
                        <el-tooltip class="item" effect="light" popper-class="liebiao_tishi" placement="bottom-start">
                            <ul v-if="caigou_bianji&&$jichuxinxi().user_id==props.row.staff_id" slot="content">
                                <li @click="dianji_xinzeng(props.row,'Xsk2pLBS9a','采购单')">新增</li>
                            </ul>
                            <!--  @click="dakai_youce_xiangqing(props.row,'合同')"  -->
                            <p  @click="dakai_youce_xiangqing(props.row,'采购单')" :class="props.row.status_pur==2?'green':(props.row.status_pur==1?'yellow':'red')"></p>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    width='180'
                    >
                    <template slot-scope="props">
                        <p>&nbsp;</p>
                        <div class="jiantou">
                            <p class="hengxian"></p>
                            <p class="el-icon-caret-right"></p>
                        </div>
                        <p>
                            <span class="shoudao">{{Number(props.row.amt_in_store_yes).toFixed(2)}}</span>
                            <span class="weishou">{{Number(props.row.amt_in_store_no).toFixed(2)}}</span>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="入库"
                    width='130'
                    sortable
                    :sort-method="ruku_paixu"
                    :render-header="danju_liebiao"
                    >
                    <template slot-scope="props">
                        <!-- <p :class="props.row.status_in_store==2?'green':(props.row.status_in_store==1?'yellow':'red')"></p> -->
                        <el-tooltip class="item" effect="light" popper-class="liebiao_tishi" placement="bottom-start">
                            <ul v-if="ruku_bianji&&$jichuxinxi().user_id==props.row.staff_id" slot="content">
                                <li @click="dianji_xinzeng(props.row,'fZ0x5qedzn','入库单')">新增</li>
                            </ul>
                            <!--  @click="dakai_youce_xiangqing(props.row,'合同')"  -->
                            <p @click="dakai_youce_xiangqing(props.row,'入库单')"  :class="props.row.status_in_store==2?'green':(props.row.status_in_store==1?'yellow':'red')"></p>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    width='180'
                    >
                    <template slot-scope="props">
                        <p>&nbsp;</p>
                        <div class="jiantou">
                            <p class="hengxian"></p>
                            <p class="el-icon-caret-right"></p>
                        </div>
                        <p>
                            <span class="shoudao">{{Number(props.row.amt_inv_yes).toFixed(2)}}</span>
                            <span class="weishou">{{Number(props.row.amt_inv_no).toFixed(2)}}</span>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="采购发票"
                    width='130'
                    sortable
                    :sort-method="caigou_fapiao_paixu"
                    :render-header="danju_liebiao"
                    >
                    <template slot-scope="props">
                        <!-- <p :class="props.row.status_pur_inv==2?'green':(props.row.status_pur_inv==1?'yellow':'red')"></p> -->
                        <el-tooltip class="item" effect="light" popper-class="liebiao_tishi" placement="bottom-start">
                            <ul v-if="caigou_fapiao_bianji&&$jichuxinxi().user_id==props.row.staff_id" slot="content">
                                <li @click="dianji_xinzeng(props.row,'u1SCMMoUwK','采购发票单')">新增</li>
                            </ul>
                            <!--  @click="dakai_youce_xiangqing(props.row,'合同')"  -->
                            <p @click="dakai_youce_xiangqing(props.row,'采购发票单')"  :class="props.row.status_pur_inv==2?'green':(props.row.status_pur_inv==1?'yellow':'red')"></p>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    width='180'
                    >
                    <template slot-scope="props">
                        <p>&nbsp;</p>
                        <div class="jiantou">
                            <p class="hengxian"></p>
                            <p class="el-icon-caret-right"></p>
                        </div>
                        <p>
                            <span class="shoudao">{{Number(props.row.amt_pay_yes).toFixed(2)}}</span>
                            <span class="weishou">{{Number(props.row.amt_pay_no).toFixed(2)}}</span>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="付款"
                    width='130'
                    sortable
                    :sort-method="fukuan_paixu"
                    :render-header="danju_liebiao"
                    >
                    <template slot-scope="props">
                        <!-- <p :class="props.row.status_pay==2?'green':(props.row.status_pay==1?'yellow':'red')"></p> -->
                        <el-tooltip class="item" effect="light" popper-class="liebiao_tishi" placement="bottom-start">
                            <ul v-if="fukuan_bianji&&$jichuxinxi().user_id==props.row.staff_id" slot="content">
                                <li @click="dianji_xinzeng(props.row,'vY9ubhTO89','付款单')">新增</li>
                            </ul>
                            <!--  @click="dakai_youce_xiangqing(props.row,'合同')"  -->
                            <p @click="dakai_youce_xiangqing(props.row,'付款单')"  :class="props.row.status_pay==2?'green':(props.row.status_pay==1?'yellow':'red')"></p>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    label="附件上传"
                    width="180"
                    >
                    <template slot-scope="props">
                        <p>
                            <!-- 2又成绿色了    2020、10、19 -->
                            <span @click="dianji_xiazai(props.row,'采购合同')" :class="props.row.status_pur_url==2?'fujian_green':'fujian_red'">采购合同</span>
                            <span @click="dianji_xiazai(props.row,'入库单')" :class="props.row.status_in_store_url==2?'fujian_green':'fujian_red'">入库单</span>
                        </p>
                        <p>
                            <span @click="dianji_xiazai(props.row,'采购发票')" :class="props.row.status_pur_inv_url==2?'fujian_green':'fujian_red'">采购发票</span>
                            <span @click="dianji_xiazai(props.row,'付款单')" :class="props.row.status_payment_url==2?'fujian_green':'fujian_red'">付款单</span>
                        </p>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 上面是看板列表 -->
            <el-table
                v-if="!kanban_ye"
                :data="gongying_shang_chanpin_liebiao"
                :cell-style="liebiao"
                height="7rem"
                :header-cell-style="biaotou"
                style="width: 100%">
                <el-table-column
                    fixed
                    label="供应商"
                    >
                    <template slot-scope="props">
                        {{props.row.supply_name}}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed
                    label="负责人"
                    >
                    <template slot-scope="props">
                        {{props.row.staff_name}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="产品"
                    >
                    <template slot-scope="props">
                        {{props.row.prod_name}}
                    </template>
                </el-table-column>
                <el-table-column
                    width='180'
                    >
                    <template slot-scope="props">
                        <p>&nbsp;</p>
                        <div class="jiantou">
                            <p class="hengxian"></p>
                            <p class="el-icon-caret-right"></p>
                        </div>
                        <p>{{props.row.qty_pur}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="采购单"
                    width='130'
                    sortable
                    :sort-method="caigou_paixu"
                    :render-header="danju_liebiao"
                    >
                    <template slot-scope="props">
                        <p :class="props.row.status_pur==2?'green':(props.row.status_pur==1?'yellow':'red')"></p>
                    </template>
                </el-table-column>
                <el-table-column
                    width='180'
                    >
                    <template slot-scope="props">
                        <p>&nbsp;</p>
                        <div class="jiantou">
                            <p class="hengxian"></p>
                            <p class="el-icon-caret-right"></p>
                        </div>
                        <p>
                            <span class="shoudao">{{Number(props.row.qty_in_store_yes).toFixed(2)}}</span>
                            <span class="weishou">{{Number(props.row.qty_in_store_no).toFixed(2)}}</span>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="入库"
                    width='130'
                    sortable
                    :sort-method="ruku_paixu"
                    :render-header="danju_liebiao"
                    >
                    <template slot-scope="props">
                        <p :class="props.row.status_in_store==2?'green':(props.row.status_in_store==1?'yellow':'red')"></p>
                    </template>
                </el-table-column>
                <el-table-column
                    width='180'
                    >
                    <template slot-scope="props">
                        <p>&nbsp;</p>
                        <div class="jiantou">
                            <p class="hengxian"></p>
                            <p class="el-icon-caret-right"></p>
                        </div>
                        <p>
                            <span class="shoudao">{{Number(props.row.qty_inv_yes).toFixed(2)}}</span>
                            <span class="weishou">{{Number(props.row.qty_inv_no).toFixed(2)}}</span>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="采购发票"
                    width='130'
                    sortable
                    :sort-method="caigou_fapiao_paixu"
                    :render-header="danju_liebiao"
                    >
                    <template slot-scope="props">
                        <p :class="props.row.status_pur_inv==2?'green':(props.row.status_pur_inv==1?'yellow':'red')"></p>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="youce_xianshi" v-if="dianji_chuxian_youce" @click="dianji_chuxian_youce=false">
            <div class="youce_box" @click.stop>
                <p class="youce_box_tit">
                    <i> </i>
                    <i class="el-icon-close" @click="dianji_chuxian_youce=false"></i>
                </p>
                <div class="transition-box">
                    <div v-if="biao_name=='采购单'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;" v-if="it.amt_pur_sum !== '0.00'">
                            <p>采购单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.staff_name!==undefined">负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.date_pur!==undefined">采购日期：{{it.date_pur}}</p>
                            <ul>
                                <li v-for='(i,index) in it.prod_list' :key="index" style="border-top:0.01rem solid #ccc;">
                                    <p>采购单列表({{index+1}})</p>
                                    <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                    <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                    <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                    <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                    <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                    <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                    <p v-if="i.qty_stock!==undefined">库存数量：{{i.qty_stock}}</p>
                                    <p v-if="i.supply_id!==undefined">供应商：{{i.supply_name}}</p>
                                    <p v-if="i.price_quotation!==undefined">产品报价：{{i.price_quotation}}</p>
                                    <p v-if="i.qty_pur!==undefined">采购数量：{{i.qty_pur}}</p>
                                    <p v-if="i.amt_pur!==undefined">采购金额：{{i.amt_pur}}</p>
                                    <p v-if="i.qty_stock_est!==undefined">预计库存：{{i.qty_stock_est}}</p>
                                </li>
                            </ul>
                            <p v-if="it.qty_pur_sum!==undefined">采购数量合计：{{it.qty_pur_sum}}</p>
                            <p v-if="it.amt_pur_sum!==undefined">采购金额合计：{{it.amt_pur_sum}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <div v-if="biao_name=='入库单'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>入库单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.staff_name!==undefined">入库员：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.date_in_store!==undefined">入库日期：{{it.date_in_store}}</p>
                            <p v-if="it.supply_name!==undefined">供应商：{{it.supply_name}}</p>
                            <ul>
                                <li v-for='(i,index) in it.prod_list' :key="index">
                                    <p>列表({{index+1}})</p>
                                    <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                    <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                    <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                    <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                    <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                    <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                    <p v-if="i.site_name!==undefined">仓库：{{i.site_name}}</p>
                                    <p v-if="i.site_no!==undefined">货位号：{{i.site_no}}</p>
                                    <p v-if="i.price_quotation!==undefined">产品报价：{{i.price_quotation}}</p>
                                    <p v-if="i.qty_in_store!==undefined">入库数量：{{i.qty_in_store}}</p>
                                    <p v-if="i.amt_in_store!==undefined">入库金额：{{i.amt_in_store}}</p>
                                </li>
                            </ul>
                            <p v-if="it.qty_in_store_sum!==undefined">入库数量合计：{{it.qty_in_store_sum}}</p>
                            <p v-if="it.amt_in_store_sum!==undefined">入库金额合计：{{it.amt_in_store_sum}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <div v-if="biao_name=='采购发票单'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>采购发票单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.staff_name!==undefined">负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.date_pay!==undefined">发票日期：{{it.date_pay}}</p>
                            <p v-if="it.supply_name!==undefined">供应商：{{it.supply_name}}</p>
                            <p v-if="it.inv_type!==undefined">发票类型：{{it.inv_type=='1'?'增值税专用发票(抵扣)':(it.inv_type=='2'?'增值税专用发票(未抵扣)':(it.inv_type=='3'?'增值税普通发票':'不开发票'))}}</p>
                            <p v-if="it.inv_code!==undefined">发票代码：{{it.inv_code}}</p>
                            <p v-if="it.inv_no!==undefined">发票号码：{{it.inv_no}}</p>
                            <ul>
                                <li v-for='(i,index) in it.prod_list' :key="index">
                                    <p>列表({{index+1}})</p>
                                    <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                    <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                    <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                    <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                    <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                    <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                    <p v-if="i.supply_id!==undefined">供应商：{{i.supply_name}}</p>
                                    <p v-if="i.price_quotation!==undefined">产品报价：{{i.price_quotation}}</p>
                                    <p v-if="i.qty_pur!==undefined">采购数量：{{i.qty_pur}}</p>
                                    <p v-if="i.amt_inv!==undefined">发票金额：{{i.amt_inv}}</p>
                                    <p v-if="i.rate_tax!==undefined">税率：{{i.rate_tax}}</p>
                                    <p v-if="i.amt_tax!==undefined">税额：{{i.amt_tax}}</p>
                                    <p v-if="i.amt_no_tax!==undefined">不含税金额：{{i.amt_no_tax}}</p>
                                </li>
                            </ul>
                            <p v-if="it.amt_tax_sum!==undefined">税额合计：{{it.amt_tax_sum}}</p>
                            <p v-if="it.amt_in_store!==undefined">入库金额：{{it.amt_in_store}}</p>
                            <p v-if="it.amt_inv_sum!==undefined">发票金额合计：{{it.amt_inv_sum}}</p>
                            <ul>
                                <li v-for='(i,index) in it.amt_list' :key="index">
                                    <p>付款金额明细({{index+1}})</p>
                                    <p v-if="i.date_pay_est!==undefined">预计付款日期：{{i.date_pay_est}}</p>
                                    <p v-if="i.amt_pay_est!==undefined">预计付款金额：{{i.amt_pay_est}}</p>
                                </li>
                            </ul>
                            <p v-if="it.amt_pay_est_sum!==undefined">预计付款金额合计：{{it.amt_pay_est_sum}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <div v-if="biao_name=='付款单'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>付款单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.operate_time!==undefined">付款日期：{{it.operate_time}}</p>
                            <p v-if="it.supply_name!==undefined">供应商：{{it.supply_name}}</p>
                            <p v-if="it.staff_name!==undefined">负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.amt_pur_inv_un_pay!==undefined">采购发票累计未结账金额：{{it.amt_pur_inv_un_pay}}</p>
                            <p v-if="it.amt_pur_un_pay!==undefined">采购单累计未付款金额：{{it.amt_pur_un_pay}}</p>
                            <p v-if="it.amt_in_store!==undefined">累计入库金额：{{it.amt_in_store}}</p>
                            <p v-if="it.amt_pay!==undefined">付款金额：{{it.amt_pay}}</p>
                            <p v-if="it.amt_pay_sum!==undefined">累计付款金额：{{it.amt_pay_sum}}</p>
                            <p v-if="it.bank_name!==undefined">开户行名称：{{it.bank_name}}</p>
                            <p v-if="it.bank_acct!==undefined">银行账号：{{it.bank_acct}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination
            v-if="kanban_ye"
            @current-change="dianji_yeshu"
            :current-page="dangqian_yeshu"
            class="fenye_qi"
            layout="total, prev, pager, next, jumper"
            :total="dangqian_zongshu">
        </el-pagination>
        <shaixuan ref="xuanze"  @sousuo='sousuo_chanpin' @shaixuan_queding='dianji_queding'></shaixuan>
        <caigoukanbanLiebiao ref='caigou_kanban_liebiao'></caigoukanbanLiebiao>
    </div>
</template>

<script>
import shaixuan from '../../components/shaixuan/Duoxuan'
import caigoukanbanLiebiao from './Purchasingkblist'
import { query_form_use_power_list, query_supply_list_of_power, query_form_list_file_nos, query_flow_info_all, query_prod_qty_of_supply_list, query_user_info, query_supply_list, generate_pur_mng_board } from '../../api/api'
export default {
  name: 'purchasingkb',
  data () {
    return {
      danwei: '',
      time: '', // 时间区间
      kanban_neirong_list: [],
      dangqian_yeshu: 1, // 当前页数
      dangqian_zongshu: 0, // 当前总数

      dangqian_gongying_shang: [],
      jichuxinxi: {},
      shifou_have_daixie: false, // 是否代写
      kan_shei: '2',
      kanshei_list: [],
      kanban_ye: true, // 是否是看板页

      gongying_shang_chanpin_liebiao: [], // 点击钻石打开的页面

      dianji_chuxian_youce: false,
      biao_name: '',
      youce_xiangqing: '',
      youce_xiangqing2: '',

      // 表单编辑权限
      caigou_bianji: false, // 采购单
      ruku_bianji: false, // 入库单
      caigou_fapiao_bianji: false, // 采购发票单
      fukuan_bianji: false, // 付款单

      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/',
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/'
      //  url:'http://192.168.3.5:8080/hwb_client/V1.0.0/',
    }
  },
  created () {
    sessionStorage.removeItem('mlbb_one_shezhi')
    sessionStorage.removeItem('mlbb_four_shezhi')
    sessionStorage.removeItem('mlbb_shenpi_zhengyi')
    sessionStorage.removeItem('mlbb_biaodan_sheji')
    sessionStorage.removeItem('mlbb_liucheng_shezhi')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_list')

    sessionStorage.removeItem('mlbb_shenpi_biaodan')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_2')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_3')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_4')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_5')
    this.jichuxinxi = this.$jichuxinxi()
    this.chaxun_tianxie_quanxian()
    this.jichu()
  },
  mounted () {
  },
  watch: {
    dangqian_yeshu () {
      this.caigou_kanban_liebiao_jiekou()
    },
    dangqian_gongying_shang () {
      this.caigou_kanban_liebiao_jiekou()
    },
    kan_shei () {
      this.caigou_kanban_liebiao_jiekou()
    }
  },
  components: { shaixuan, caigoukanbanLiebiao },
  methods: {
    jichu () {
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body.dept_manager == 1 || res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
            this.kanshei_list = [
              {
                name: '看自己',
                id: '2'
              },
              {
                name: '看员工',
                id: '1'
              },
              {
                name: '看全部',
                id: '3'
              }
            ]
          } else {
            this.kanshei_list = [
              {
                name: '看自己',
                id: '2'
              }
            ]
          }
        }
      })
      this.caigou_kanban_liebiao_jiekou()
    },
    chaxun_tianxie_quanxian () {
      query_form_use_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const cuowu = []
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.forEach(item => {
            item.children.forEach(it => {
              if (it.flow_id == 'Xsk2pLBS9a') { // 采购单
                this.caigou_bianji = true
              } else if (it.flow_id == 'fZ0x5qedzn') { // 入库单
                this.ruku_bianji = true
              } else if (it.flow_id == 'u1SCMMoUwK') { // 采购发票单
                this.caigou_fapiao_bianji = true
              } else if (it.flow_id == 'vY9ubhTO89') { // 付款单
                this.fukuan_bianji = true
              }
            })
          })
        } else if (res.data.code == 500) {}
      })
    },
    caigou_kanban_liebiao_jiekou () {
      generate_pur_mng_board({
        data: {
          ent_id: this.$ent_id(),
          search_str: null, // 搜索字段
          supply_id: this.dangqian_gongying_shang.length != 0 ? this.dangqian_gongying_shang.map(item => item.supply_id).join(',') : null, // 供应商id
          look_type: this.kan_shei,
          page_size: '10',
          page_index: this.dangqian_yeshu + '',
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.list.length != 0) {
            this.dangqian_zongshu = date.total
            this.kanban_neirong_list = date.list
          } else {
            this.dangqian_zongshu = 0
            this.kanban_neirong_list = []
          }
        } else if (res.data.code == 500) {
          alert('系统报错')
        }
      })
    },
    tongguo_jujue (num) {
      if (num == 4) {
        return '审批通过'
      } else if (num == 2) {
        return '审批拒绝'
      }
    },
    // 点击进入采购管理
    dianji_jinru_caigou_guanli () {
      this.$router.push('/purchasing')
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;font-size:0.14rem;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;font-size:0.12rem;'
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    dianji_xiazai (i, name) {
      this.$bendi_cunfang_cunchu('mlbb_xiaoshou_tian', { date: i, name: name }, '暂时')
      this.$router.push('/importexport_xiaoshou')
    },
    dianji_queding (val) {
      console.log(val)
      this[val.ziduan] = val.list
    },
    // 点击选择供应商
    dianji_xuanze_gongying_shang (yixuan) {
      if (yixuan != undefined && yixuan != null) {
      } else {
        yixuan = this.dangqian_gongying_shang
      }
      this.gongying_shang_xiaolei(yixuan)
    },
    gongying_shang_xiaolei (yixuan) {
      // query_supply_list({data:{
      //     ent_id:this.$ent_id()
      // }}).then(res=>{
      //     console.log(res)
      //     if(res.data.code==200){
      //         let date=JSON.parse(res.data.body.data)
      //         this.$refs.xuanze.jichu('选择供应商',null,date,yixuan,null,null,'supply_name','supply_id')
      //         this.$refs.xuanze.bangding('dangqian_gongying_shang')
      //     }else if(res.data.code==500){
      //         alert('查询供应商list报错')
      //     }
      // })
      query_supply_list_of_power({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        // search_str:val.text.length!=0?val.text:null,
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.$refs.xuanze.jichu('选择供应商', null, date, yixuan, null, null, 'supply_name', 'supply_id')
          this.$refs.xuanze.bangding('dangqian_gongying_shang')
        } else if (res.data.code == 500) {
          alert('查询供应商list报错')
        }
      })
    },
    wuyong () {},
    // 采购排序
    caigou_paixu (a, b) {
      const val1 = a.status_pur
      const val2 = b.status_pur
      return val1 - val2
    },
    // 入库排序
    ruku_paixu (a, b) {
      const val1 = a.status_in_store
      const val2 = b.status_in_store
      return val1 - val2
    },
    // 采购发票排序
    caigou_fapiao_paixu (a, b) {
      const val1 = a.status_pur_inv
      const val2 = b.status_pur_inv
      return val1 - val2
    },
    // 付款排序
    fukuan_paixu (a, b) {
      const val1 = a.status_pay
      const val2 = b.status_pay
      return val1 - val2
    },
    ceshi (i, b, c) {
      console.log(b)
      console.log(c)
      if (!this.kanban_ye) {
        if (this.gongying_shang_chanpin_liebiao.length != 0) {
          this.$refs.caigou_kanban_liebiao.jichu(i)
          this.$refs.caigou_kanban_liebiao.sousuo_zifu(this.gongying_shang_chanpin_liebiao[0].supply_id)
        }
      } else {
        this.$refs.caigou_kanban_liebiao.jichu(i)
      }
      // tongxun.$emit('liebiao_name',i)
      //
      // this.$router.push('/kanbanlist')
    },
    // 测试漏斗
    danju_liebiao (h, { column }) {
      // console.log(column)
      // console.log(h)
      return (
        <div>
          <i class='el-icon-s-grid' style='margin-right:0.06rem;' on-click={() => {
            this.ceshi({
              name: column.label,
              look_type: this.kan_shei
            }, column, h)
          }}></i>
          <span>{column.label}</span>
        </div>
      )
    },
    sousuo_chanpin (val) {
      query_supply_list({
        data: {
          ent_id: this.$ent_id(),
          search_str: val.text.length != 0 ? val.text : null
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.$refs.xuanze.sousuo_xiaolei(date, 'supply_name', 'supply_id')
        } else if (res.data.code == 500) {
          alert('查询供应商list报错')
        }
      })
    },
    dianji_zuanshi (val) {
      console.log(val)
      query_prod_qty_of_supply_list({
        data: {
          ent_id: this.$ent_id(),
          supply_id: val.supply_id,
          supply_name: val.supply_name
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.forEach(item => {
            this.$set(item, 'supply_id', val.supply_id)
          })
          this.gongying_shang_chanpin_liebiao = date
          this.kanban_ye = false
        } else if (res.data.code == 500) {}
      })
    },
    dianji_xinzeng (i, flow_id, flow_name) {
      query_flow_info_all({
        data: {
          ent_id: this.$ent_id(),
          flow_id: flow_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = res.data.body.form_str
          console.log(date)
          const data = []
          for (let a = 0; a < date.length; a++) {
            if (date[a].defaultImportant != undefined) {
              date[a].defaultImportant = date[a].defaultImportant != 'false'
            }
            if (date[a].defaultPrint != undefined) {
              date[a].defaultPrint = date[a].defaultPrint != 'false'
            }
            if (date[a].defaultOptions != undefined) {
              date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
            }
            if (date[a].gongshi != undefined) {
              date[a].gongshi = JSON.parse(date[a].gongshi)
            }
            if (date[a].supportSetting != undefined) {
              date[a].supportSetting = JSON.parse(date[a].supportSetting)
            }
            if (date[a].components != undefined) {
              date[a].components = JSON.parse(date[a].components)
              for (let b = 0; b < date[a].components.length; b++) {
                if (date[a].components[b].defaultImportant != undefined) {
                  if (typeof (date[a].components[b].defaultImportant) === 'string') {
                    date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                  }
                }
                if (date[a].components[b].defaultPrint != undefined) {
                  if (typeof (date[a].components[b].defaultPrint) === 'string') {
                    date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                  }
                }
                // typeof(str)=='string'
                if (date[a].components[b].defaultOptions != undefined) {
                  if (typeof (date[a].components[b].defaultOptions) === 'string') {
                    date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                  }
                }
                if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                  if (typeof (date[a].components[b].gongshi) === 'string') {
                    console.log(date[a].components[b].gongshi)
                    date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                  }
                }
                if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                  if (typeof (date[a].components[b].supportSetting) === 'string') {
                    console.log(date[a].components[b].supportSetting)
                    date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                  }
                }
              }
            }
            data.push(date[a])
          }
          const obj = {
            flow_id: flow_id,
            flow_name: flow_name
          }
          console.log(i)

          if (flow_id == 'fZ0x5qedzn') { // 入库单
            data.forEach(item => {
              if (item.componentName == 'suppliername' && item.defaultLable == '供应商名称') {
                this.$set(item, 'option', [{
                  supply_name: i.supply_name,
                  supply_id: i.supply_id
                }])
                this.$set(item, 'value', i.supply_name)
                this.$set(item, 'value_id', i.supply_id)
              }
            })
          } else if (flow_id == 'u1SCMMoUwK') { // 采购发票单
            data.forEach(item => {
              if (item.componentName == 'suppliername' && item.defaultLable == '供应商') {
                this.$set(item, 'option', [{
                  supply_name: i.supply_name,
                  supply_id: i.supply_id
                }])
                this.$set(item, 'value', i.supply_name)
                this.$set(item, 'value_id', i.supply_id)
              }
            })
          } else if (flow_id == 'vY9ubhTO89') { // 付款单
            data.forEach(item => {
              if (item.componentName == 'suppliername' && item.defaultLable == '供应商') {
                this.$set(item, 'option', [{
                  supply_name: i.supply_name,
                  supply_id: i.supply_id
                }])
                this.$set(item, 'value', i.supply_name)
                this.$set(item, 'value_id', i.supply_id)
              }
            })
          }
          console.log(data)
          this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
          this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
          this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '看板', '暂时')
          this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_4', i, '暂时')
          this.$router.push('/usetable')
        } else if (res.data.code == 500) {}
      })
    },
    dakai_youce_xiangqing (i, name) {
      console.log(i)

      this.youce_xiangqing = ''
      this.youce_xiangqing2 = ''
      this.biao_name = name
      let flow_id = ''
      if (name == '采购单') {
        flow_id = 'Xsk2pLBS9a'
      } else if (name == '付款单') {
        flow_id = 'vY9ubhTO89'
      } else if (name == '采购发票单') {
        flow_id = 'u1SCMMoUwK'
      } else if (name == '入库单') {
        flow_id = 'fZ0x5qedzn'
      }
      query_form_list_file_nos({
        data: {
          ent_id: this.$ent_id(),
          supply_id: i.supply_id,
          flow_id: flow_id,
          staff_id: i.staff_id
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.forEach(it => {
            this.$set(it, 'zidingyi', it.extra != undefined ? JSON.parse(it.extra) : [])
          })
          if (date.length != 0) {
            this.youce_xiangqing = date
            this.dianji_chuxian_youce = true
          }
        } else if (res.data.code == 500) {}
      })
    },
    dianji_daochu () {
      let date = ''
      if (this.gongying_shang_chanpin_liebiao.length != 0) {
        date = this.url + 'export_supply_prod_qty_list?ent_id=' + this.$ent_id() + '&supply_id=' + this.gongying_shang_chanpin_liebiao[0].supply_id
        this.$func.multiDownLoad([date])
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../style/salesman.scss';
 @import './chanpin.scss';
</style>
